import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import parseJwt from "../../../funtions/parseJwt";
import BodyManageContent from "../../../layouts/Body/BodyManageContent";
import HomeLayout from "../../../layouts/HomeLayout/HomeLayout";
import { common } from "../../../utils/common";
import { toast } from "react-toastify";
import { getInfoAccount,updateInfoAccount } from "../../../api";


const HomeContent = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageContent.HomePageContent",
  });
  return (
    <div className="homepagecontent_layout">
      <div className="homepagecontent_top">{t("my_content")}</div>

      <div className="allcontent_bottom">
        <BodyManageContent myContent={true} />
      </div>
    </div>
  );
};

export default function HomePageContent() {
  const { setUserInfo } = useAppContext();
  const { i18n } = useTranslation("translation");

  const {
    userInfo: { accountID },
  } = useAppContext();
  const { t } = useTranslation("translation", {
    keyPrefix: "pages.HomePageOrg.OrganizationDetailPage",
  });
  const [infoAccount, setInfoAccount] = useState({
    fullName: "",
    email: "",
    language: "",
    phone: "",
  });

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);


    // console.log('json new',json);
    // const language = json?.local || 'vi';
    // i18n.changeLanguage(language);


    setUserInfo(json);
  }, []);


  const loadData = useCallback(async () => {
      if (!accountID) return;
      try {
        const response = await getInfoAccount(accountID);
        const jwt = response.data.jwt;
        const infoAccountData = parseJwt(jwt);
        setInfoAccount({
          fullName: infoAccountData.name,
          email: infoAccountData.contactEmail,
          language: infoAccountData.defaultLocale,
          phone: infoAccountData.phone || "",
        });
        i18n.changeLanguage(infoAccountData.defaultLocale);
      } catch (error) {
        toast.error(t("connection_error"));
      }
    }, [accountID]);

    useEffect(() => {
      loadData();
    }, [loadData]);

  return (
    <div>
      <HomeLayout
        addContent={true}
        newFolder={true}
        bodyManage={<HomeContent />}
        type="Manage Content"
      />
    </div>
  );
}
