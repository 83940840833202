import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import App from "./App";
import "./assets/styles/global.scss";
import AppProvider from "./context/AppContext";
import parseJwt from "./funtions/parseJwt";
import routes from "./routes/index";
import i18n from "./translation/i18n";
import { common } from "./utils/common";

function AuthChecker() {
  const [roleId, setRoleId] = useState("");
  const [userInfo, setUserInfo] = useState({
    accountID: "",
    email: "",
    id: "",
  });

  useEffect(() => {
    const JWT_loginToken = common.getToken();
    const json = parseJwt(JWT_loginToken);
    const language = json?.locale || 'vi';
    i18n.changeLanguage(language);

    setUserInfo(json);
    const newRoleId = json.roleId;
    setRoleId(newRoleId);
  }, []);

  const unauthorizedRoutes = routes.filter((route) => route.roleId === 7);

  return (
    <AppProvider
      value={{
        userInfo,
        setUserInfo,
      }}
    >
      <App roleId={roleId} unauthorizedRoutes={unauthorizedRoutes} />
    </AppProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <I18nextProvider i18n={i18n}>
    <AuthChecker />
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    {/* Same as */}
    <ToastContainer />
  </I18nextProvider>
);
